/* Your existing CSS for the SideNav */

.header_section .menu a span {
    position: relative;
}
.header_section .menu a span::after {
    content: "";
    display: block;
    margin: 0 auto;
    height: 4px;
    width: 0;
    top: 5px;
    background: transparent;
    transition: all 0.3s;
    transition: transform 0.5s;
    transform: scaleX(0);
    transform-origin: right;
    right: 0;
}
.header_section .menu a span:hover::after {
    width: 60%;
    background: #ffffff;
    transform: scaleX(1);
    border-radius: 5px;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
    text-align: center;
}
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}
.sidenav .closebtn {
    position: absolute;
    top: 10px;
    right: 0px;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    font-size: 10px !important;
}
.header-row.open {
    z-index: 1;
}
/* CSS for the header layout */
.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}
.heaer_section {
    background-color: #333;
}
.logo img {
    width: 200px; /* Set the desired width for your logo */
}
.menu {
    justify-content: right;
    width: 70%;
    display: flex;
}
.menu a {
    text-decoration: none;
    color: #FFFFFF;
    padding: 10px;
    margin: 0 30px;
    font-size: 20px;
    font-weight: 600;
    opacity: 0.8;
    transition: all 0.3s;
    transition: transform 0.5s;
}
.menu a:nth-child(4) {
    margin-right: 0px;
}
.menu a:hover {
    opacity: 1;
    transition: all 0.3s;
    transition: transform 0.5s;
}
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    top: 40px;
    background-color: #ffffff;
    min-width: 215px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: all 0.3s;
    transition: transform 0.5s;
  }
  .dropdown-content a {
    color: #161513;
    padding: 10px 10px;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {background-color: #FFFFFF;}
  .dropdown:hover .dropdown-content {display: block;transition: all 0.3s;transition: transform 0.5s;}
  .dropbtn::before {
    font-family: FontAwesome;
    content: "\f107";
    position: absolute;
    right: -15px;
    top: 4px;
    font-size: medium;
    text-align: center;
    color: #ffffff; 
  }


.toggle-icon {
    font-size: 30px;
    cursor: pointer;
    color: white; 
}
.inner-menu {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 0px;
    max-width: 1280px;
    margin: auto;
}
.menu-row {
    text-align: left;
    width: 100%;
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}
.menu_25x_width {
    padding: 10px;
}
#humberger_inner_body_layout {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}
.humberger_inner_body_layer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
}
.menu-heading h2 {
    color: #1D1D1F;
    font-size: 90px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -4.5px;
}
.menu-subheading ul li a{
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: #161513;
    padding: 0px 0px 5px 5px;
    transition: all .4s ease;

}
.menu-subheading ul li {
    list-style: none;
}
.our_studio_layout .our_studio_body-layout h4 {
    color: #1D1D1F;
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    padding: 0px 15px;
    margin-bottom: 20px;
}
.our_studio_body-layout ul {
    display: inline-flex;
    list-style: none;
    padding: 0px 0px 0px 20px;
}
.our_studio_body-layout ul li a {
    padding: 0px 10px 0px 0px;
    border-right: 1px solid #BFBFBF;
    margin-right: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #161513;
}
.himberger_menu_inner_footer_module {
    border-top: 1px solid #E2D8D8;
}
.himberger_menu_inner_footer_module .reach-us-heading {
    padding: 15px 20px 0px 20px;
}
.himberger_menu_inner_footer_module .reach-us-heading h5 {
    margin: 0;
    color: #1D1D1F;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
}
.humberger-menu-inner-footer-layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 75px;
    display: inline-flex;
    align-items: center;
    width: 100%;
}
.inner-footer-column {
    padding: 15px 20px;
}

.inner-footer-column h5 {
    margin: 0;
    color: #1D1D1F;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
    
}
#humbergerMenuInnerFooterLayer .inner-footer-column p,
#humbergerMenuInnerFooterLayer .inner-footer-column p a {
    color: #1D1D1F;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.9px;
    padding: 0;
    margin: 0;
}
#humbergerMenuInnerFooterLayer .inner-footer-column p a:hover {
    color: #0A12E2;
}
.our_studio_body-layout ul li a:hover {
    color: #1F66FF;
}
.inner-footer-column ul {
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.inner-footer-column:nth-child(4) {
    padding-right: 0px;
}
.inner-footer-column li {
    padding: 0;
    padding: 5px;
    margin: 10px;
    border-radius: 45px;
    background: #0159E9;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
}
.humberger-inner-social-layout ul li a {
    padding: 0 !important;
    font-size: 10px !important;
}
.humberger-inner-social-layout ul li:hover {
    transform: scale(1.1);
}
.humberger-inner-social-layout ul li {
    transition: 0.3s;
}
.menu-subheading {
    position: relative;
}
.menu-subheading ul li:before {
    content: "";
    background-image: url('../css/images/header/right-arrow-icon.svg');
    width: 22px;
    height: 30px;
    display: inline-block;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    margin-left: -25px;
    transition: all .4s ease;
}
.menu-subheading ul li:hover a   {
    color: #0159E9;
}
/* .menu-subheading ul:hover li:before {
    width: 30px;
    margin-right: 8px;
} */

.menu-column a img {
    width: 130px;
}
@media screen and (max-height: 450px) {
.sidenav {
    padding-top: 15px;
}
.sidenav a {
    font-size: 18px;
}
}
