.job-details-content .back-btn   {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    color: #ffffff;
    margin-left: 38px;
    cursor: pointer;
}
.job-details-content .back-btn {
    margin-bottom: 30px;
    position: relative;
    display: flex;
}
.back-btn:before {
    content: "";
    background-image: url('../css/images/pichers-voice/left-arrow-icon.svg');
    width: 30px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .2s linear;
    margin-left: -35px;
}
.job-details-content a {
    text-decoration: none;
}
.jd-post-details {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 30px;
}
.jd-post-details .jd-text-block {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #FFF;
    text-align: center;
    margin-right: 15px;
    border-right: 4px solid #fff;
    padding-right: 15px;
    text-transform: capitalize;
}
.roles-and-resposibilities-layer p strong {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    color: #2C004E;
}
.roles-and-resposibilities-layer p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 200%;
    color: #646464;
}
.job-details-content h2 {
    color: #FFF;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
    font-size: 50px;
    font-weight: 600;
    line-height: 60px; 
    text-transform: capitalize;
}
.job-details-content .share-btn {
    margin-left: 30px;
}
.job-details-content .share-btn:hover {
    background-color: transparent;
}
.jd-body-layout {
    padding: 40px 0px;
}
.roles-and-resposibilities-layer h3 {
    font-size: 38px;
    font-weight: 600;
    line-height: 45px;
    color: #2C004E;
}
.roles-and-resposibilities-layer ul li {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 200%;
    color: #646464;
}
.roles-and-resposibilities-layer ul {
    margin-bottom: 31px;
}

.submit_your-application-layer {
    background-image: url("../css/images/submit-your-bg-img.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding: 40px
  }
.submit_your-application-layer h4 {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    color: #2C004E;
}
.submit_your-application-layer .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 30px 35px;
}
.form-submitBtn {
    margin: auto;
}



input[type="text"],
input[type="email"],
input[type="password"],
button {
  font: inherit;
  line-height: 1;
}
p {
  margin: 0;
}
.dialog {
  max-width: 100%;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  position: relative;
}
.submit-dailog-model .dialog h5 {
    font-size: 24px;
}
.submit-dailog-model .dialog h5 b {
    color: rgba(3, 8, 116, 0.90);
}
.submit-dailog-model .dialog {
    padding: 2.5rem;
}
.submit-dailog-model button.btn.btn-outlined {  
    position: relative;
    top: 0;
    right: 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    padding: 5px 25px;
}
#submitModel img {
    margin: auto;
    display: flex;
    margin-bottom: 20px;
}
.submit-dailog-model button.btn.btn-outlined:hover {
    background-color: rgba(3, 8, 116, 0.90);
    color: #ffffff;
}
.dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}
.dialog-close-btn {
  font-size: .75em;
  position: absolute;
  top: .25em;
  right: .25em;
}
.dialog button {
    margin-top: 0;
    padding: 0 4px;
    border: 1px solid;
    background-color: transparent;
}
.dialog p {
    font-size: 16px;
    color: black;
}
.dialog h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}
.jd-social-share {
    width: 100%;
    position: relative;
}
.jd-social-share ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
.jd-social-share ul li img {
    border-radius: 0px 0px 4px 4px;
    background: rgba(217, 217, 217, 0.20);
    width: 40px;
    height: 32.8px;
    padding: 10px 12px;
    cursor: pointer;
}
.jd-social-share ul li {
    margin: 0;
    padding: 0;
    font-size: 16px;
    width: 100%;
    height: 100%;
    line-height: 66px;
}
.roles-and-resposibilities-layer p u {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.5;
    color: #2C004E;
}

/* Media Query For Mobile Devices */
@media only screen and (max-width: 600px) { 

    .submit_your-application-layer {
        padding: 20px;
    }
    .submit_your-application-layer h4 {
        font-size: 24px;
    }
    .submit_your-application-layer .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        margin: 15px auto;
        width: 100%;
    }
    .form-box-layout button.btn.btn-primary {
        margin: 15px auto;
        width: 100%;
        margin-top: 0 !important;
    }
    .roles-and-resposibilities-layer h3 {
        font-size: 22px;
        line-height: 1.2;
    }
    .jd-body-layout {
        padding: 20px 20px;
    }
    .job-details-content .back-btn a {
        font-size: 16px;
    }
    .job-details-content .back-btn {
        margin-bottom: 10px;
    }
    .job-details-content .back-btn {
        font-size: 16px;
    }
    .job-details-content h2 {
        font-size: 30px;
        line-height: 1.2;
        margin: 0;
    }
    .jd-post-details .jd-text-block {
        font-size: 14px;
        padding-right: 20px;
    }
    .jd-post-details {
        margin-top: 10px;
    }
    #job-details-main-layout .banner_module .text-block {
        top: 30%;
    }
    #job-details-main-layout .share-btn img {
        height: 100%;
        width: 20px;
    }
    .job-details-content .share-btn {
        margin-left: 20px;
    }
    .roles-and-resposibilities-layer ul li {
        font-size: 18px;
    }
    .roles-and-resposibilities-layer p {
        font-size: 18px;
        line-height: 1.5;
    }
    .roles-and-resposibilities-layer p strong {
        font-size: 16px;
    }
    .roles-and-resposibilities-layer p u {
        font-size: 18px;
    }

}
  