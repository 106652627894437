.footer-section {
width: 100%;
position: relative;
display: inline-block;
padding-top: 50px;
}
.footer-main {
width: 100%;
position: relative;
display: inline-block;
padding: 0 40px;
}

.footer-row {
width: 100%;
display: flex;
justify-content: space-between;
padding-bottom: 40px;
border-bottom: 1px solid rgba(10, 20, 47, 0.2);
}
.footer-row.extra-class {
margin-bottom: 40px;
border: none;
padding-top: 50px;
padding-bottom: 0;
gap: 6.5rem;
}
.footer-row.third-row .footer-colmn:last-child {
text-align: right;
}
.footer-colmn .footer-logo {
    width: 130px;
}
.text-column p {
margin: 0;
}
.footer-row.third-row {
padding: 40px;
border: none;
position: relative;
align-items: center;

}
.social-icons .icons:last-child {
margin-right: 0;
}
.footer-main .footer-row .footer-colmn {
width: 20%;
position: relative;
display: inline-block;
}

.footer-main .footer-row.extra-class .footer-colmn {
width: 25%;
position: relative;
display: inline-block;
}

.footer-main .footer-row.third-row .footer-colmn {
width: 50%;
position: relative;
display: inline-block;
}
.contact-info {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    margin-top: 10px;
    align-items: center;
}
.contact-info .image {
margin-right: 10px;
}
.social-icons {
display: flex;
width: 100%;
position: relative;
}
.footer-colmn .subheading a:hover {
   color: #0A12E2;
}
.icons {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid rgba(10, 20, 47, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.bottom_footer {
    width: 100%;
    position: relative;
    display: inline-block;
    --bs-gutter-y: 0;
    --bs-gutter-x: 1.5rem;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    background: rgba(217, 217, 217, 0.2);
}
.footer-row .heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: rgba(10, 20, 47, 1);
    margin-bottom: 5px;
}
.footer-row.extra-class .footer-colmn, .footer-row.third-row .footer-colmn  {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
}
.footer-row.extra-class .footer-colmn .paragraph {
        padding-top: 20px;
        padding-bottom: 20px;
}
.footer-row.extra-class .footer-colmn .contact-info .phone a{
    color: #0A142F;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
}
.text-column p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0px;
    /* text-align: center; */
    color: rgba(10, 20, 47, 1);
}
.footer-colmn a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: #0A142F;
    opacity: 0.8;
    text-decoration: none;
}
.footer-colmn .social-icons .icons {
    transition: 0.3s;
}
.footer-colmn .social-icons .icons:hover {
    transform: scale(1.1);
}
.footer-row.extra-class .footer-colmn .contact-info .phone:hover a {
    color: #0A12E2;
}
@media only screen and (max-width:920px) {
    .footer-row.extra-class {
        gap: 2rem;
    }
}

@media only screen and (max-width:767px) {
.footer-row {
    display: inline-block;
}
.footer-main .footer-row .footer-colmn {
    width: 100%;
}
.footer-main .footer-row.extra-class .footer-colmn {
    width: 100%;
}
.footer-row.third-row .footer-colmn {
    width: 100%;
}
.footer-colmn>div {
    margin-bottom: 30px;
}
.footer-colmn:last-child>div {
    margin-bottom: 0;
}
.footer-row.third-row {
    display: flex;
    width: 100%;
    flex-flow: column-reverse;
    flex-wrap: wrap;
}
.text-column p {
    margin-top: 30px;
    margin-bottom: 0;
}
.icons {
    width: 38px;
    height: 38px;
}
}